import store from "@/store";
import { User } from "@/store/modules/AuthModule";
import router from "@/router";
import { watch } from "vue";
import { Mutations } from "@/store/enums/StoreEnums";

export default class RoleCheck {
  user = {} as User;
  redirectRole = "";
  redirectPath = "";
  defaultUser = {
    name: "",
    email: "",
    password: "",
    api_token: "",
    role: ["Guest"],
    id: "",
    merchant_id: "",
    username: "",
    phone: "",
    sub_domain: "",
  } as User;
  constructor() {
    watch(
      () => store.getters.currentUser,
      (newValue) => {
        if (Object.keys(newValue).length > 0) {
          this.user = newValue;
          this.ifRoleRedirectTo(this.redirectRole, this.redirectPath, "id");
        }
      },
    );
  }
  getUser() {
    this.user = store.getters.currentUser;
    if (!this.user || !this.user.role) {
      this.user = this.defaultUser;
    }
  }

  isSuperAdmin() {
    this.getUser();
    const userRole = this.user?.role;
    return userRole ? userRole.includes("ReCo Super Users") : false;
  }

  isAdmin() {
    this.getUser();
    const userRole = this.user?.role;
    return userRole ? userRole.includes("ReCo Admin") : false;
  }

  isSupport() {
    this.getUser();
    const userRole = this.user?.role;
    return userRole ? userRole.includes("ReCo Support") : false;
  }

  isMerchantAdmin() {
    this.getUser();
    const userRole = this.user?.role;
    return userRole ? userRole.includes("Merchant Admin") : false;
  }

  isMerchantUser() {
    this.getUser();
    const userRole = this.user?.role;
    return userRole ? userRole.includes("Merchant User") : false;
  }

  isUser() {
    this.getUser();
    const userRole = this.user?.role;
    return userRole ? userRole.includes("User") : false;
  }
  roleList(roles: string[]) {
    let can = false;
    const arrOut: string[] = [];
    for (const role of roles) {
      if (this.user.role && this.user.role.includes(role)) {
        can = true;
      }
      if (can) {
        arrOut.push(role);
      }
    }
    return arrOut;
  }
  ifRoleRedirectTo(role: string, pathName: string, param = "") {
    if (this.user.role && this.user.role.includes(role)) {
      this.redirectRole = "";
      this.redirectPath = "";
      const pushOptions = { name: pathName };
      if (param) {
        pushOptions["params"] = {};
        pushOptions["params"][param] = this.user.id;
      }
      router.push(pushOptions);
    } else {
      this.redirectRole = role;
      this.redirectPath = pathName;
    }
    if (
      !this.isSuperAdmin() &&
      !this.isAdmin() &&
      window.location.hostname.split(".").length > 2
    ) {
      this.checkRoleSubDomain(this.user);
    }
  }
  checkRoleSubDomain(user: User) {
    const domain = window.location.hostname.split(".")[0];
    const userDomain = user.sub_domain;
    if (userDomain && userDomain !== domain) {
      const { href } = router.resolve({ name: "sign-in" });
      let hostname = "";
      hostname = this.changeSubDomain(userDomain);
      const origin =
        window.location.protocol + "//" + hostname + ":" + window.location.port;
      store.commit(Mutations.PURGE_AUTH);
      window.open(origin + href, "_self");
      return false;
    }
    return true;
  }

  private changeSubDomain(domain: string) {
    const domainParts = window.location.hostname.split(".");
    domainParts[0] = domain;
    return domainParts.join(".");
  }

  checkAccessByMerchant(merchantId: string) {
    if (
      this.user.merchant_id &&
      this.user.merchant_id !== merchantId &&
      (this.isMerchantAdmin() || this.isMerchantUser())
    ) {
      if (this.checkRoleSubDomain(this.user)) {
        router.push({ name: "merchant-dashboard" });
      }
    }
  }
}
