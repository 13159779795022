import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class SecureCheckoutModule extends VuexModule {
  showSecureModal = false;

  get getSecureModalState(): boolean {
    return this.showSecureModal;
  }

  @Mutation
  [Mutations.SHOW_SECURE_MODAL](state) {
    this.showSecureModal = state;
  }
}
