import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Links, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import router from "@/router";

export interface User {
  name: string;
  email: string;
  password: string;
  api_token: string;
  role: string[];
  id: string;
  merchant_id: string;
  username: string;
  phone: string;
  sub_domain: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  previousRoute = null;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get storageData(): object {
    return JSON.parse(window.localStorage.getItem("user-data") as string);
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get getPreviousRoute() {
    return this.previousRoute;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.errors = {};
    JwtService.saveToken(user.authorisation.api_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_TO_STORAGE](user) {
    const data = {
      merchant_id: "merchant_id" in user ? user.merchant_id : null,
      role: user.role[0],
      username: "username" in user ? user.username : "",
      id: "id" in user ? user.id : "",
    };
    window.localStorage.setItem("user-data", JSON.stringify(data));
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    window.localStorage.setItem("user-data", JSON.stringify(this.user));
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_PREV_ROUTE](route) {
    this.previousRoute = route;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("api/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_USER, data.user);
        this.context.commit(Mutations.SET_USER_TO_STORAGE, data.user);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          error: [response.data.message],
        });
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.SET_PREV_ROUTE, null);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("api/register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_USER, data.user);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("api/forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return ApiService.post("api/reset_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/verify_token", payload)
        .then(({ data }) => {
          const user = data.user;
          const parts = window.location.hostname.split(".");
          if (
            user.sub_domain &&
            parts.length > 2 &&
            user.sub_domain !== parts[0]
          ) {
            this.context.commit(Mutations.PURGE_AUTH);
            parts[0] = user.sub_domain;
            let href = window.location.protocol + "//" + parts.join(".");
            if (window.location.port.length > 0) {
              href += ":" + window.location.port;
            }
            window.location.href = href;
          } else {
            this.context.commit(Mutations.SET_AUTH, data);
            this.context.commit(Mutations.SET_USER, data.user);
            this.context.commit(Mutations.SET_USER_TO_STORAGE, data.user);
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          } else {
            this.context.commit(Mutations.SET_ERROR, "Unauthorised");
          }
          this.context.commit(Mutations.PURGE_AUTH);
          router.push({ name: Links.SIGN_IN_PAGE });
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
