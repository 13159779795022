import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

@Module
export default class PathModule extends VuexModule {
  data: Array<object> = [];

  get getPathLogData(): Array<object> {
    return this.data;
  }
  @Mutation
  [Mutations.SET_LOG_DATA](object: object) {
    this.data.push(object);
  }
}
