import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import ParcelModule from "@/store/modules/ParcelModule";
import CartModule from "@/store/modules/CartModule";
import SecureCheckoutModule from "@/store/modules/SecureCheckoutModule";
import SettingsModule from "@/store/modules/SettingsModule";
import MerchantModule from "@/store/modules/MerchantModule";
import UsersModule from "@/store/modules/UsersModule";
import ReportsModule from "@/store/modules/ReportsModule";
import PathModule from "@/store/modules/PathModule";

config.rawError = true;

const store = createStore({
  modules: {
    UsersModule,
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    ParcelModule,
    CartModule,
    SecureCheckoutModule,
    SettingsModule,
    MerchantModule,
    ReportsModule,
    PathModule,
  },
});

export default store;
