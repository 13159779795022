import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module
export default class ParcelModule extends VuexModule {
  parcels = [];
  paymentTypes = {};
  search = "";
  searchType = "";
  cycleLabels = {};

  get getParcels(): Array<object> {
    return this.parcels;
  }
  get getCycleLabels(): object {
    return this.cycleLabels;
  }

  get getBillTypes(): object {
    return this.paymentTypes;
  }

  get searchSting(): string {
    return this.search;
  }

  get getSearchType(): string {
    return this.searchType;
  }

  @Mutation
  [Mutations.SET_SEARCH_STRING](string) {
    this.search = string;
  }

  @Mutation
  [Mutations.SET_SEARCH_TYPE](type) {
    this.searchType = type;
  }

  @Mutation
  [Mutations.SET_PARCELS_ARRAY](array) {
    this.parcels = array;
  }

  @Mutation
  [Mutations.SET_CYCLE_LABELS](labels: object) {
    this.cycleLabels = labels;
  }

  @Mutation
  [Mutations.SET_PAYMENT_TYPES]() {
    if (Object.keys(this.paymentTypes).length === 0) {
      const methodUrl = `/api/getTypeList`;
      ApiService.get(methodUrl)
        .then((response) => {
          const data = response.data.body;
          data.forEach((item) => {
            this.paymentTypes[item.VAL] = item.MSG;
          });
        })
        .catch((error) => {
          console.log("error created");
          console.log(error);
        })
        .finally(() => {
          console.log("done");
        });
    }
  }

  @Action
  [Actions.LOAD_CYCLE_LABELS]() {
    ApiService.get("/api/getCycleLabels").then(({ data }) => {
      this.context.commit(Mutations.SET_CYCLE_LABELS, data);
    });
  }
}
