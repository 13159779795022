<template>
  <router-view />
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onMounted,
  computed,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { ThemeModeComponent } from "@/assets/ts/layout";
import UserActivityMonitorService from "@/core/services/UserActivityMonitorService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();
    const setWindowHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`,
      );
      document.documentElement.style.setProperty(
        "--vw",
        `${window.innerWidth * 0.01}px`,
      );
      window.addEventListener("resize", () => {
        // Set a CSS variable to the window height
        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight * 0.01}px`,
        );
        document.documentElement.style.setProperty(
          "--vw",
          `${window.innerWidth * 0.01}px`,
        );
      });
    };

    const isUserAuthenticated = computed(
      () => store.getters.isUserAuthenticated,
    );
    let userActivityMonitorService: UserActivityMonitorService;

    onMounted(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      store.commit(Mutations.GET_ADMIN_SETTINGS);

      setWindowHeight();

      nextTick(() => {
        initializeComponents();

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });

      ThemeModeComponent.setMode("light", "light");

      router.afterEach(() => {
        nextTick(() => {
          if (isUserAuthenticated.value) {
            userActivityMonitorService = new UserActivityMonitorService();
            userActivityMonitorService.startMonitoring();
          }
        });
      });
    });

    onUnmounted(() => {
      if (userActivityMonitorService) {
        userActivityMonitorService.stopMonitoring();
      }
    });
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

//RTL version styles
//@import "assets/css/style.rtl.css";

#app {
  display: contents;
}
main::-webkit-scrollbar,
span::-webkit-scrollbar,
ol::-webkit-scrollbar,
ul::-webkit-scrollbar,
pre::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
main::-webkit-scrollbar-thumb,
span::-webkit-scrollbar-thumb,
ol::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
pre::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background: #0095e8;
}
:root,
[data-theme="light"] {
  --kt-scrollbar-hover-color: #0095e8;
}
body.app-dark-sidebar.swal2-shown.swal2-height-auto {
  height: 100% !important;
}
@media (pointer: none), (pointer: coarse) {
  .modal-container {
    min-height: fit-content;
    max-height: calc(var(--vh, 1vh) * 100);
    max-width: calc(var(--vw, 1vw) * 100);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }
}
</style>
