import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module
export default class ReportsModule extends VuexModule {
  merchantName = "";
  departmentId = 0;
  reportsDepartments = [];
  reports = [];
  reportsError = {};

  get getReports(): Array<object> {
    return this.reports;
  }
  @Mutation
  [Mutations.SET_REPORTS](array) {
    this.reports = array;
  }
  get getReportsDepartments(): Array<object> {
    return this.reportsDepartments;
  }
  @Mutation
  [Mutations.SET_REPORTS_DEPARTMENTS](array) {
    this.reportsDepartments = array;
  }
  get getDepartmentId(): number {
    return this.departmentId;
  }
  @Mutation
  [Mutations.SET_DEPARTMENT_ID](id) {
    this.departmentId = id;
  }
  get getMerchantName(): string {
    return this.merchantName;
  }
  @Mutation
  [Mutations.SET_MERCHANT_NAME](name) {
    this.merchantName = name;
  }
  get getReportsError(): object {
    return this.reportsError;
  }
  @Mutation
  [Mutations.SET_REPORTS_ERROR](error) {
    this.reportsError = { ...error };
  }
  @Mutation
  [Mutations.CLEAN_REPORTS_ERROR]() {
    this.reportsError = {};
  }
  @Action
  [Actions.LOAD_REPORTS_DEPARTMENTS](id) {
    this.context.commit(Mutations.CLEAN_REPORTS_ERROR);
    this.context.commit(Mutations.SET_REPORTS_DEPARTMENTS, []);
    return ApiService.get(`api/departments-reports/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REPORTS_DEPARTMENTS, data.data);
        this.context.commit(Mutations.SET_MERCHANT_NAME, data.merchant);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_REPORTS_ERROR, {
          error: [response.data.message],
        });
      });
  }
}
