import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface Parcel {
  id: number | string;
  isAuth: boolean;
  department_id?: string;
  payment_settings?: object;
}
@Module
export default class CartModule extends VuexModule {
  parcels: Parcel[] = [];
  showCart = false;

  constructor(module) {
    super(module);
    const stored = CartModule.getFromStorage();
    if (stored?.length) {
      this.parcels = stored;
    }
  }
  get getCartRows(): Array<object> {
    const isAuth = store.getters.isUserAuthenticated;
    if (isAuth) {
      return this.parcels;
    }
    return this.parcels.filter((item) => !item.isAuth);
  }
  get getCartState(): boolean {
    return this.showCart;
  }
  @Mutation
  [Mutations.ADD_TO_CART](object: Parcel) {
    let dp = store.getters.getAvailableDepartments.filter((item) => {
      return item.url === store.getters.getDepartment;
    });

    if (dp.length > 0 && !object.department_id) {
      object.department_id = dp[0].id;
    }

    if (dp.length > 0 && object.department_id !== dp[0].id) {
      dp = store.getters.getAvailableDepartments.filter((item) => {
        return item.id === object.department_id;
      });
    }

    if (dp.length > 0 && !object.payment_settings) {
      object.payment_settings = dp[0].payment_settings;
    }

    object.isAuth = store.getters.isUserAuthenticated;
    this.parcels.push(object);
    CartModule.setToStorage(this.parcels);
  }
  @Mutation
  [Mutations.SHOW_CART](state) {
    this.showCart = state;
  }
  @Mutation
  [Mutations.DELETE_CART_ITEM](id) {
    this.parcels = this.parcels.filter((item) => item.id !== id);
    CartModule.setToStorage(this.parcels);
  }
  @Mutation
  [Mutations.CLEAR_CART]() {
    const isAuth = store.getters.isUserAuthenticated;
    if (isAuth) {
      this.parcels = [];
    } else {
      this.parcels = this.parcels.filter((item) => item.isAuth);
    }
    CartModule.setToStorage(this.parcels);
  }
  private static setToStorage(cart: Array<object>) {
    localStorage.removeItem("user-cart");
    localStorage.setItem("user-cart", JSON.stringify(cart));
  }
  private static getFromStorage() {
    return JSON.parse(<string>localStorage.getItem("user-cart"));
  }
}
