import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class MerchantModule extends VuexModule {
  merchantErrors = {};
  departments = {};
  availableDepartments: Array<object> = [];
  department = "";
  departmentData = {};
  merchant = {};
  merchants = [];
  merchantsList = [];
  merchantInfo = {};
  roll_type_real = 0;
  domains: Array<string> = [];
  get getMerchantErrors(): object {
    return this.merchantErrors;
  }
  get getDepartments(): object {
    return this.departments;
  }
  get getAvailableDepartments(): Array<object> {
    return this.availableDepartments;
  }
  get getDepartment(): string {
    return this.department;
  }
  get getMerchantInfo(): object {
    return this.merchantInfo;
  }
  get getMerchant(): object {
    return this.merchant;
  }
  get getMerchants(): object {
    return this.merchants;
  }
  get getMerchantsList(): object {
    return this.merchantsList;
  }
  get getRollType(): number {
    return this.roll_type_real;
  }
  get getDomains(): Array<string> {
    return this.domains;
  }
  @Mutation
  [Mutations.SET_MERCHANT_ERROR](error) {
    this.merchantErrors = { ...error };
  }
  @Mutation
  [Mutations.SET_DEPARTMENTS](data) {
    this.departments = data;
  }
  @Mutation
  [Mutations.SET_AVAILABLE_DEPARTMENTS](data) {
    this.availableDepartments = data;
  }
  @Mutation
  [Mutations.SET_DEPARTMENT](id) {
    this.department = id;
  }
  @Mutation
  [Mutations.SET_DEPARTMENT_DATA](data) {
    this.departmentData = data;
  }
  @Mutation
  [Mutations.SET_MERCHANT_INFO](data) {
    this.merchantInfo = data;
  }
  @Mutation
  [Mutations.SET_MERCHANT](data) {
    this.merchant = data;
  }
  @Mutation
  [Mutations.SET_MERCHANTS](data) {
    this.merchants = data;
  }
  @Mutation
  [Mutations.SET_MERCHANTS_LIST](data) {
    this.merchantsList = data;
  }
  @Mutation
  [Mutations.CLEAN_MERCHANT_ERROR]() {
    this.merchantErrors = {};
  }
  @Mutation
  [Mutations.SET_ROLL_TYPE](type: number) {
    this.roll_type_real = type;
  }
  @Mutation
  [Mutations.SET_DOMAINS](domains: Array<string>) {
    this.domains = domains;
  }
  @Action
  [Actions.LOAD_MERCHANTS]() {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    this.context.commit(Mutations.SET_MERCHANTS, []);
    return ApiService.get("api/settings")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.LOAD_MERCHANTS_DOMAINS]() {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.get("api/domains")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOMAINS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.LOAD_MERCHANTS_LIST]() {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    this.context.commit(Mutations.SET_MERCHANTS_LIST, []);
    return ApiService.get("api/merchants-list")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANTS_LIST, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SET_MERCHANT_DATA](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    this.context.commit(Mutations.SET_MERCHANT, {});
    return ApiService.post("api/save-merchant", values)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANT, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_MERCHANT_DATA](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    this.context.commit(Mutations.SET_MERCHANT, {});
    return ApiService.put(`api/save-merchant/${values.id}`, values)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANT, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_MERCHANT_STATUS](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    this.context.commit(Mutations.SET_MERCHANTS, []);
    return ApiService.post(`api/merchant/update-status/${values.id}`, values)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANTS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.GET_DEPARTMENTS]() {
    if (Object.keys(this.departments).length === 0) {
      return ApiService.get("api/merchant/departments")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DEPARTMENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_MERCHANT_ERROR, {
            error: [response.data.message],
          });
        });
    }
  }
  @Action
  [Actions.GET_DEPARTMENTS_LIST]() {
    if (this.availableDepartments.length === 0) {
      return ApiService.get("api/get-departments")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AVAILABLE_DEPARTMENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_MERCHANT_ERROR, {
            error: [response.data.message],
          });
        });
    }
  }
  @Action
  [Actions.SET_CARD_POINT](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.post("api/merchant/save-card-point", values)
      .then(({ data }) => {
        return data.success;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SET_DEPARTMENT_SETTINGS](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.post("api/merchant/save-settings", values)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.LOAD_MERCHANT_DATA](id) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.get(`api/settings/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANT, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SET_DEPARTMENT_STATUS](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.post("api/merchant/department-status", values)
      .then(({ data }) => {
        return data.success;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SET_ALL_DEPARTMENT_STATUS](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.post("api/merchant/department-status-all", values)
      .then(({ data }) => {
        return data.success;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.DELETE_MERCHANT](id) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    this.context.commit(Mutations.SET_MERCHANTS, []);
    return ApiService.delete(`api/merchant/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MERCHANTS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SET_DEPARTMENT_HIDE_FROM_USER](values) {
    this.context.commit(Mutations.CLEAN_MERCHANT_ERROR);
    return ApiService.post("api/merchant/department-hidden", values)
      .then(({ data }) => {
        return data.success;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MERCHANT_ERROR, {
          error: [response.data.message],
        });
      });
  }
}
