import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      return window.location.hostname.split(".").length < 3
        ? "/wrong-url"
        : "/departments";
    },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/departments",
        name: "departments",
        component: () => import("@/views/DepartmentsPage.vue"),
        meta: {
          pageTitle: "Departments",
          breadcrumbs: [
            {
              text: "Home",
              to: "/admin/dashboard",
            },
            {
              text: "Departments",
              to: "/departments",
            },
          ],
        },
      },
      {
        path: "/:department_id/search",
        name: "app-parcel-search",
        component: () => import("@/views/apps/parcel/ParcelSearch.vue"),
        meta: {
          pageTitle: "Parcels Listing",
          breadcrumbs: [
            {
              text: "Departments",
              to: "/departments",
            },
            {
              text: () => {
                return store.getters.getDepartment;
              },
              to: "/departments",
            },
          ],
        },
      },
      {
        path: "/overview-parcel/:parcelId/:year/:department_id",
        name: "app-overview-parcel",
        component: () => import("@/views/apps/parcel/OverviewParcel.vue"),
        meta: {
          pageTitle: "Parcels Listing",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Tax Search",
              to: "/departments",
            },
            {
              text: "Overview Parcel",
              to: "/",
            },
          ],
        },
      },
      {
        path: "/blind-payments/:department_id",
        name: "blind-payments",
        component: () => import("@/views/apps/blind/BlindPayments.vue"),
        meta: {
          pageTitle: "Department",
          breadcrumbs: [
            {
              text: "Departments",
              to: "/departments",
            },
            {
              text: "Blind Payments",
              to: "/blind-payments",
            },
          ],
        },
      },
      {
        path: "/url/:department_id",
        name: "url",
        component: () => import("@/views/apps/blind/UrlPayments.vue"),
        meta: {
          pageTitle: "Department",
          breadcrumbs: [
            {
              text: "Departments",
              to: "/departments",
            },
            {
              text: "URL Payments",
              to: "/url",
            },
          ],
        },
      },
      {
        path: "/user-profile/:id",
        name: "user-profile",
        component: () =>
          import("@/views/apps/users/components/AddUserPage.vue"),
        meta: {
          pageTitle: "Department",
          breadcrumbs: [
            {
              text: "Home",
              to: "/",
            },
            {
              text: "My Profile",
              to: "/user-profile",
            },
          ],
        },
      },
      {
        path: "/account-view/:id/:account/:client",
        name: "user-account-view",
        component: () =>
          import("@/views/apps/users/components/AccountView.vue"),
        meta: {
          pageTitle: "View Acct/Pay",
          breadcrumbs: [
            {
              text: "Home",
              to: "/",
            },
            {
              text: "My Profile",
              to: "/user-profile/:id",
            },
            {
              text: "View Acct/Pay",
              to: "/users/account-view",
            },
          ],
        },
      },
      {
        path: "/merchant-account-view/:account/:client",
        name: "merchant-account-view",
        component: () =>
          import("@/views/apps/users/components/AccountView.vue"),
        meta: {
          pageTitle: "View Acct/Pay",
          breadcrumbs: [
            {
              text: "Home",
              to: "/",
            },
            {
              text: "View Acct/Pay",
              to: "/users/account-view",
            },
          ],
        },
      },
      {
        path: "/:department_id/import-payments",
        name: "import-payments",
        component: () => import("@/views/apps/importPayment/ImportPayment.vue"),
        meta: {
          pageTitle: "View Acct/Pay",
          breadcrumbs: [
            {
              text: "Departments",
              to: "/departments",
            },
            {
              text: () => {
                return store.getters.getDepartment;
              },
              to: "/import-payments",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
          ],
        },
      },
      {
        path: "/dashboard",
        name: "merchant-dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
          ],
        },
      },
      {
        path: "/admin/settings",
        name: "settings",
        component: () => import("@/views/apps/settings/AdminSettings.vue"),
        meta: {
          pageTitle: "Settings",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Merchants",
              to: "/admin/settings",
            },
          ],
        },
      },
      {
        path: "/admin/settings/add-settings/:id?",
        name: "add-settings",
        component: () =>
          import("@/views/apps/settings/AddMerchantSettings.vue"),
        meta: {
          pageTitle: "Add Settings",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Merchants",
              to: "/admin/settings",
            },
            {
              text: "Settings",
              to: "add-settings",
            },
          ],
        },
      },
      {
        path: "/admin/users/merchants",
        name: "users",
        component: () =>
          import("@/views/apps/users/MerchantUsersDashboard.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Merchants",
              to: "/admin/users",
            },
          ],
        },
      },
      {
        path: "/admin/users/search",
        name: "users-search",
        component: () => import("@/views/apps/users/UsersSearch.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Search",
              to: "/admin/users-search",
            },
          ],
        },
      },
      {
        path: "/admin/users/merchants/:merchantId",
        name: "users-list",
        component: () => import("@/views/apps/users/UsersDashboard.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Merchants",
              to: "/admin/users",
            },
            {
              text: "Users",
              to: "/admin/users/:merchantId",
            },
          ],
        },
      },
      {
        path: "/users/:merchantId",
        name: "merchant-users-list",
        component: () => import("@/views/apps/users/UsersDashboard.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Merchants",
              to: "/admin/users",
            },
            {
              text: "Users",
              to: "/admin/users/:merchantId",
            },
          ],
        },
      },
      {
        path: "/users/:merchantId",
        name: "merchant-users-list",
        component: () => import("@/views/apps/users/UsersDashboard.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Users",
              to: "/users/:merchantId",
            },
          ],
        },
      },
      {
        path: "/admin/users/merchants/add-user/:id?",
        name: "add-user",
        component: () =>
          import("@/views/apps/users/components/AddUserPage.vue"),
        meta: {
          pageTitle: "User Profile",
          breadcrumbs: [
            {
              text: "Users",
              to: "/admin/users",
            },
            {
              text: "User Profile",
              to: "/admin/users/add-user",
            },
          ],
        },
      },
      {
        path: "/users/add-user/:id?",
        name: "merchant-add-user",
        component: () =>
          import("@/views/apps/users/components/AddUserPage.vue"),
        meta: {
          pageTitle: "User Profile",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Users",
              to: "/admin/users",
            },
            {
              text: "User Profile",
              to: "/users/add-user",
            },
          ],
        },
      },
      {
        path: "/admin/users/account-view/:id/:account/:client",
        name: "account-view",
        component: () =>
          import("@/views/apps/users/components/AccountView.vue"),
        meta: {
          pageTitle: "User Profile",
          breadcrumbs: [
            {
              text: "Users",
              to: "/admin/users",
            },
            {
              text: "User Profile",
              to: "/admin/users/add-user/:id",
            },
            {
              text: "View Acct/Pay",
              to: "/admin/users/account-view",
            },
          ],
        },
      },
      {
        path: "/admin/reports",
        name: "reports",
        component: () => import("@/views/apps/reports/ReportsDashboard.vue"),
        meta: {
          pageTitle: "Posting Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Posting Report",
              to: "/admin/reports",
            },
          ],
        },
      },
      {
        path: "/reports",
        name: "merchant-reports",
        component: () => import("@/views/apps/reports/ReportsDashboard.vue"),
        meta: {
          pageTitle: "Posting Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Posting Report",
              to: "/reports",
            },
          ],
        },
      },
      {
        path: "/admin/reports/departments/:id",
        name: "reports-departments",
        component: () => import("@/views/apps/reports/DepartmentsReports.vue"),
        meta: {
          pageTitle: "Posting Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Posting Report",
              to: "/admin/reports",
            },
            {
              text: "Departments",
              to: "/admin/reports/departments",
            },
          ],
        },
      },
      {
        path: "/reports/departments/:id",
        name: "merchant-reports-departments",
        component: () => import("@/views/apps/reports/DepartmentsReports.vue"),
        meta: {
          pageTitle: "Posting Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Departments",
              to: "/reports/departments",
            },
          ],
        },
      },
      {
        path: "/admin/reports/transactions/:merchant/:department",
        name: "transactions",
        component: () => import("@/views/apps/reports/Transactions.vue"),
        meta: {
          pageTitle: "Posting Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Posting Report",
              to: "/admin/reports",
            },
            {
              text: "Departments",
              to: "/admin/reports/departments/:merchant",
            },
            {
              text: "Transactions",
              to: "/admin/reports/transactions",
            },
          ],
        },
      },
      {
        path: "/reports/transactions/:merchant/:department",
        name: "merchant-transactions",
        component: () => import("@/views/apps/reports/Transactions.vue"),
        meta: {
          pageTitle: "Posting Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Departments",
              to: "/reports/departments/:merchant",
            },
            {
              text: "Transactions",
              to: "/reports/transactions",
            },
          ],
        },
      },
      {
        path: "/admin/transactions-reports",
        name: "transactions-reports",
        component: () =>
          import("@/views/apps/postingReports/ReportsDashboard.vue"),
        meta: {
          pageTitle: "Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Transaction Report",
              to: "/admin/transactions-reports",
            },
          ],
        },
      },
      {
        path: "/transactions-reports",
        name: "merchant-transactions-reports",
        component: () =>
          import("@/views/apps/postingReports/ReportsDashboard.vue"),
        meta: {
          pageTitle: "Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Transaction Report",
              to: "/transactions-reports",
            },
          ],
        },
      },
      {
        path: "/admin/transactions-reports/departments/:id",
        name: "transactions-reports-departments",
        component: () =>
          import("@/views/apps/postingReports/DepartmentsReports.vue"),
        meta: {
          pageTitle: "Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Transaction Report",
              to: "/admin/transactions-reports",
            },
            {
              text: "Departments",
              to: "/admin/transactions-reports/departments",
            },
          ],
        },
      },
      {
        path: "/transactions-reports/departments/:id",
        name: "merchant-transactions-reports-departments",
        component: () =>
          import("@/views/apps/postingReports/DepartmentsReports.vue"),
        meta: {
          pageTitle: "Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Departments",
              to: "/transactions-reports/departments",
            },
          ],
        },
      },
      {
        path: "/admin/transactions-reports/transactions/:merchant/:department",
        name: "dayle-transactions",
        component: () => import("@/views/apps/postingReports/Transactions.vue"),
        meta: {
          pageTitle: "Transaction Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Transaction Report",
              to: "/admin/transactions-reports",
            },
            {
              text: "Departments",
              to: "/admin/transactions-reports/departments/:merchant",
            },
            {
              text: "Transactions",
              to: "/admin/transactions-reports/transactions",
            },
          ],
        },
      },
      {
        path: "/transactions-reports/transactions/:merchant/:department",
        name: "merchant-dayle-transactions",
        component: () => import("@/views/apps/postingReports/Transactions.vue"),
        meta: {
          pageTitle: "Transaction Report",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/dashboard",
            },
            {
              text: "Departments",
              to: "/transactions-reports/departments/:merchant",
            },
            {
              text: "Transactions",
              to: "/transactions-reports/transactions",
            },
          ],
        },
      },
      {
        path: "/import/accounts",
        name: "import",
        component: () => import("@/views/apps/import/ImportPage.vue"),
        meta: {
          pageTitle: "Import Files",
          breadcrumbs: [
            {
              text: "Import Files",
              to: "/import/accounts",
            },
          ],
        },
      },
      {
        path: "/admin/import-users",
        name: "import-users",
        component: () => import("@/views/apps/users/EZSPImport.vue"),
        meta: {
          pageTitle: "Import Users",
          breadcrumbs: [
            {
              text: "Import Users",
              to: "/admin/import-users",
            },
          ],
        },
      },
      {
        path: "/search-for-bill",
        name: "search-for-bill",
        component: () => import("@/views/apps/bills/SearchForBills.vue"),
        meta: {
          pageTitle: "Search For Bills",
          breadcrumbs: [
            {
              text: "Search For Bills",
              to: "/search-for-bill",
            },
          ],
        },
      },
      //// Merchant notifications
      {
        path: "/admin/notifications",
        name: "merchant-notifications",
        component: () =>
          import("@/views/apps/merchants/MerchantNotificationsDashboard.vue"),
        meta: {
          pageTitle: "Notifications",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Notifications",
              to: "/notifications/:merchantId",
            },
          ],
        },
      },
      {
        path: "/notifications/:merchantId",
        name: "merchant-notifications-list",
        component: () =>
          import("@/views/apps/merchants/MerchantNotificationsTable.vue"),
        meta: {
          pageTitle: "Notifications",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Notifications",
              to: "/notifications/:merchantId",
            },
          ],
        },
      },
      {
        path: "/notifications/:merchantId/notification/:type/:notificationId?",
        name: "merchant-notification-info",
        component: () =>
          import("@/views/apps/merchants/MerchantNotificationInfo.vue"),
        meta: {
          pageTitle: "Notification",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Notifications",
              to: "/notifications/:merchantId",
            },
            {
              text: "Notification",
              to: "/notifications/:merchantId/notification/:notificationId",
            },
          ],
        },
      },
      ////
      {
        path: "/import/import-permit",
        name: "import-permit",
        component: () => import("@/views/apps/import/PermitComponent.vue"),
        meta: {
          pageTitle: "Import Permit",
          breadcrumbs: [
            {
              text: "Import Permit",
              to: "/import/import-permit",
            },
          ],
        },
      },
      {
        path: "/import/import-aging",
        name: "import-aging",
        component: () => import("@/views/apps/import/AgingComponent.vue"),
        meta: {
          pageTitle: "Import Aging Data",
          breadcrumbs: [
            {
              text: "Import Aging Data",
              to: "/import/import-aging",
            },
          ],
        },
      },
      //Warnings start
      {
        path: "/admin/warnings",
        name: "warnings-settings",
        component: () => import("@/views/apps/warnings/WarningsSettings.vue"),
        meta: {
          pageTitle: "Warnings",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Warnings",
              to: "/admin/warnings",
            },
          ],
        },
      },
      {
        path: "/admin/warnings/recaptcha",
        name: "warnings-recaptcha",
        component: () => import("@/views/apps/warnings/WarningsRecaptcha.vue"),
        meta: {
          pageTitle: "Warnings reCaptcha",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Warnings",
              to: "/admin/warnings",
            },
            {
              text: "Warnings reCaptcha",
              to: "/admin/warnings/recaptcha",
            },
          ],
        },
      },
      //Warnings end
      //Maintenance Start
      {
        path: "/admin/maintenance",
        name: "maintenance-settings",
        component: () =>
          import("@/views/apps/maintenance/MaintenanceSettings.vue"),
        meta: {
          pageTitle: "Maintenance",
          breadcrumbs: [
            {
              text: "Dashboards",
              to: "/admin/dashboard",
            },
            {
              text: "Maintenance",
              to: "/admin/maintenance",
            },
          ],
        },
      },
      //Maintenance End
    ],
  },
  {
    path: "/sign-in",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
      {
        path: "/password-reset-confirm",
        name: "password-reset-confirm",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordResetConfirm.vue"
          ),
        meta: {
          pageTitle: "Password reset confirm",
        },
      },
      {
        path: "/notifications-unsubscribe/:token",
        name: "notifications-unsubscribe",
        component: () =>
          import("@/views/apps/merchants/MerchantNotificationsUnsubscribe.vue"),
        meta: {
          pageTitle: "Unsubscribe from eBilling and Notifications",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/wrong-url",
        name: "wrong-url",
        component: () => import("@/views/WrongUrl.vue"),
        meta: {
          pageTitle: "Oops!",
          type: "error",
        },
      },
      {
        path: "/print-receipt/:id",
        name: "print-receipt",
        component: () => import("@/views/apps/reports/PrintReceipt.vue"),
        meta: {
          pageTitle: "Print Receipt",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // verify auth token before each page change
  store
    .dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() })
    .then(() => {
      store.commit(Mutations.SET_LOG_DATA, {
        path: to.path,
        params: to.params,
        query: to.query,
        name: to.name,
        meta: to.meta,
        fullPath: to.fullPath,
        user: store.getters.currentUser,
      });
      if (
        to.fullPath &&
        to.fullPath.trim() !== "/" &&
        to.fullPath.trim() !== "/sign-in"
      ) {
        store.commit(Mutations.SET_PREV_ROUTE, to.fullPath);
      }
      // current page view title
      const settings = store.getters.adminSettings;
      let title = to.meta.pageTitle;
      if (to.params.department_id !== undefined) {
        let depName = "";
        const param: string = to.params.department_id as string;
        const nameArr = param.split("-");
        nameArr.forEach((item: string) => {
          depName += item.charAt(0).toUpperCase() + item.slice(1) + " ";
        });
        title = depName;
      }
      if (
        settings !== undefined &&
        settings.name.length &&
        window.location.hostname.split(".").length > 2
      ) {
        document.title = `${settings.name} - ${title}`;
      } else {
        document.title = `ReCo Anywhere | ${title}`;
      }
      if (to.meta.type === "error") {
        document.title = `${to.meta.pageTitle}`;
      }
      //document.title = `Greene County, OH Tax Payments`;

      // reset config to initial state
      store.commit(Mutations.RESET_LAYOUT_CONFIG);

      if (to.meta.middleware == "auth") {
        if (store.getters.isUserAuthenticated) {
          next();
        } else {
          next({ name: "sign-in" });
        }
      } else if (
        store.getters.isUserAuthenticated &&
        (to.name == "sign-in" || to.name == "sign-up")
      ) {
        if (window.location.hostname.split(".").length < 3) {
          const user = store.getters.currentUser;
          if (!user.sub_domain || user.sub_domain === "null") {
            next("admin/dashboard");
          } else {
            next({ name: "wrong-url" });
          }
        } else {
          next("departments");
        }
      } else {
        next();
      }

      // Scroll page to top on every route change
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
});

export default router;
