import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module
export default class UsersModule extends VuexModule {
  users = [];
  roles = [];
  usersErrors = {};
  paginationData = {};
  searchPaginationData = {};
  searchedUsers = [];

  get getUsers(): Array<object> {
    return this.users;
  }
  get getSearchedUsers(): Array<object> {
    return this.searchedUsers;
  }
  get getPaginationData(): object {
    return this.paginationData;
  }
  @Mutation
  [Mutations.SET_PAGINATION_DATA](data) {
    this.paginationData = data;
  }
  get getSearchPaginationData(): object {
    return this.searchPaginationData;
  }
  @Mutation
  [Mutations.SET_SEARCH_PAGINATION_DATA](data) {
    this.searchPaginationData = data;
  }
  @Mutation
  [Mutations.SET_USERS](array) {
    this.users = array;
  }
  @Mutation
  [Mutations.SET_SEARCHED_USERS](array) {
    this.searchedUsers = array;
  }
  get getRoles(): string[] {
    return this.roles;
  }
  @Mutation
  [Mutations.SET_USER_ROLES](array) {
    this.roles = array;
  }
  get getUsersErrors(): object {
    return this.usersErrors;
  }
  @Mutation
  [Mutations.SET_USERS_ERROR](error) {
    this.usersErrors = { ...error };
  }
  @Mutation
  [Mutations.CLEAN_USERS_ERROR]() {
    this.usersErrors = {};
  }
  @Action
  [Actions.LOAD_USERS](queryString) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    this.context.commit(Mutations.SET_PAGINATION_DATA, {});
    return ApiService.get(`api/users?${queryString}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS, data.data);
        this.context.commit(Mutations.SET_PAGINATION_DATA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SEARCH_USERS](queryString) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    this.context.commit(Mutations.SET_SEARCH_PAGINATION_DATA, {});
    return ApiService.get(`api/users-search?${queryString}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SEARCHED_USERS, data.data);
        this.context.commit(Mutations.SET_SEARCH_PAGINATION_DATA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.LOAD_USER](id) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    return ApiService.get(`api/users/${id}`)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_USER](values) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    return ApiService.put(`api/users/${values.id}`, values)
      .then(({ data }) => {
        console.log(data.message);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPDATE_USER_PROFILE](values) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    return ApiService.put(`api/user/${values.id}`, values)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.LOAD_USER_ROLES]() {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    return ApiService.get("api/user-roles")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ROLES, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.DELETE_USER](id) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    return ApiService.delete(`api/users/${id}`)
      .then(({ data }) => {
        console.log(data.message);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
  @Action
  [Actions.SAVE_USER](values) {
    this.context.commit(Mutations.CLEAN_USERS_ERROR);
    return ApiService.post("api/users", values)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USERS_ERROR, {
          error: [response.data.message],
        });
      });
  }
}
